import { api as SegmentJSAPI } from '@optimizely/segment-js';

import { toJS } from 'optly/immutable';
import { actions as LayerActions } from 'optly/modules/entity/layer';

import CurrentLayerActions from 'bundles/p13n/modules/current_layer/actions';

import { actions as PublishStatusActions } from 'bundles/p13n/modules/publish_status';
import LiveCommitTagActions from 'optly/modules/entity/live_commit_tag/actions';
import VerifierActions from 'optly/modules/verifier/actions';

const waitForReindex = () => {
  return new Promise(resolve => setTimeout(resolve, 1000));
};

/**
 * Activate the live tag
 * @param {Layer} currentLayer
 * @param {Function} onSuccessAction - callbackFn
 */
export function startLayer(currentLayer, onSuccessAction) {
  const currentLayerJS = toJS(currentLayer);
  const layerId = currentLayerJS.id;

  PublishStatusActions.startStarting(layerId);
  CurrentLayerActions.startCampaign(currentLayerJS)
    .then(onSuccessAction)
    .finally(() => {
      PublishStatusActions.finishStarting(layerId);
    });
}

/**
 * Dectivate the live tag
 * @param {Layer} currentLayer
 * @param {Function} onSuccessAction - callbackFn
 */
export function pauseLayer(currentLayer, onSuccessAction) {
  const layerId = currentLayer.get('id');

  // TODO (asa): Move pausing state out of component module and into current layer module?
  PublishStatusActions.startPausing(layerId);
  CurrentLayerActions.pauseCampaign(toJS(currentLayer))
    .then(onSuccessAction)
    .finally(() => {
      PublishStatusActions.finishPausing(layerId);
      /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
      SegmentJSAPI.track('Experiment Paused');
    });
}

/**
 * Archive and Pause the layer
 * @param {Layer} layer
 * @param {number} currentProjectId
 * @param {LiveCommit} liveCommit
 * @param {Function} onSuccessAction - callbackFn
 */
export function archiveAndPauseLayer(
  layer,
  currentProjectId,
  liveCommit,
  onSuccessAction,
) {
  PublishStatusActions.startPausing(layer.id);

  LayerActions.archive(layer)
    .then(() => {
      LiveCommitTagActions.deactivateTag({
        layer_id: liveCommit.get('layer_id'),
        // Purposefully don't pipe the result of the verifier as it takes
        // too long for the snippet to update
      })
        .then(updatedLiveCommit => {
          VerifierActions.verify({
            projectId: currentProjectId,
            revisionToVerify: updatedLiveCommit.project_code_revision,
          }); // don't error on rejection
        })
        .then(() => waitForReindex())
        .always(() => {
          PublishStatusActions.finishPausing(layer.id);
        });
    })
    .then(() => waitForReindex())
    .then(onSuccessAction);
}

/**
 * Archive the layer
 * @param {Layer} layer
 * @param {Function} onSuccessAction - callbackFn
 */
export function archiveLayer(layer, onSuccessAction) {
  LayerActions.archive(layer)
    .then(() => waitForReindex())
    .then(onSuccessAction);
}

/**
 * Unarchive the layer.
 * @param {Layer} layer
 * @param {Function} onSuccessAction - callbackFn
 */
export function unarchiveLayer(layer, onSuccessAction) {
  LayerActions.unarchive(layer).then(onSuccessAction);
}

/**
 * Conclude and Pause the layer
 * @param {Layer} layer
 * @param {string} result
 * @param {string} conclusions
 * @param {number} currentProjectId
 * @param {number} experimentId
 * @param {Function} onSuccessAction - callbackFn
 */
export function concludeAndPauseLayer(
  plainLayer,
  result,
  conclusions,
  currentProjectId,
  experimentId,
  onSuccessAction,
) {
  LayerActions.concludeAndPause(
    plainLayer,
    result,
    conclusions,
    currentProjectId,
  )
    .then(onSuccessAction)
    .then(() => LayerActions.syncLayerExperimentData(experimentId));
}

/**
 * Conclude the layer
 * @param {Layer} layer
 * @param {string} result
 * @param {string} conclusions
 * @param {number} experimentId
 * @param {Function} onSuccessAction - callbackFn
 */
export function concludeLayer(
  plainLayer,
  result,
  conclusions,
  experimentId,
  onSuccessAction,
) {
  LayerActions.conclude({
    ...plainLayer,
    concluded_results_outcome: result,
    concluded_conclusions: conclusions,
  })
    .then(onSuccessAction)
    .then(() => LayerActions.syncLayerExperimentData(experimentId));
}

/**
 * Conclude and Deploy the layer
 * @param {Number} experimentId
 * @param {Array<Number>} deployedVariationIds
 * @param {String} audienceConditions
 * @param {Number} layerHoldback
 * @param {Object} plainLayer
 * @param {string} result
 * @param {object} conclusionValues
 * @param {string} conclusionValues.result
 * @param {string} conclusionValues.conclusions
 * @param {Function} onSuccessAction - callbackFn
 */
export function concludeAndDeployLayer(
  experimentId,
  deployedVariationIds,
  audienceConditions,
  layerHoldback,
  plainLayer,
  conclusionValues,
  onSuccessAction,
) {
  LayerActions.concludeAndDeployLayer(
    plainLayer,
    conclusionValues,
    experimentId,
    deployedVariationIds,
    audienceConditions,
    layerHoldback,
  )
    .then(onSuccessAction)
    .then(() =>
      LayerActions.syncDataAfterDeploy(
        plainLayer.id,
        experimentId,
        plainLayer.type,
        plainLayer.project_id,
      ),
    );
}

export default {
  startLayer,
  pauseLayer,
  archiveLayer,
  archiveAndPauseLayer,
  unarchiveLayer,
  concludeAndDeployLayer,
  concludeAndPauseLayer,
  concludeLayer,
};
